// ----------------------------------------------------------------------
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export function remToPx(value) {
    return Math.round(parseFloat(value) * 16);
}
export function pxToRem(value) {
    return "".concat(value / 16, "rem");
}
export function responsiveFontSizes(_a) {
    var sm = _a.sm, md = _a.md, lg = _a.lg;
    return {
        '@media (min-width:600px)': {
            fontSize: pxToRem(sm),
        },
        '@media (min-width:900px)': {
            fontSize: pxToRem(md),
        },
        '@media (min-width:1200px)': {
            fontSize: pxToRem(lg),
        },
    };
}
// ----------------------------------------------------------------------
var FONT_PRIMARY = 'Public Sans, sans-serif'; // Google Font
// const FONT_SECONDARY = 'CircularStd, sans-serif'; // Local Font
var typography = {
    fontFamily: FONT_PRIMARY,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: __assign({ fontWeight: 800, lineHeight: 80 / 64, fontSize: pxToRem(40) }, responsiveFontSizes({ sm: 52, md: 58, lg: 64 })),
    h2: __assign({ fontWeight: 800, lineHeight: 64 / 48, fontSize: pxToRem(32) }, responsiveFontSizes({ sm: 40, md: 44, lg: 48 })),
    h3: __assign({ fontWeight: 700, lineHeight: 1.5, fontSize: pxToRem(24) }, responsiveFontSizes({ sm: 26, md: 30, lg: 32 })),
    h4: __assign({ fontWeight: 700, lineHeight: 1.5, fontSize: pxToRem(20) }, responsiveFontSizes({ sm: 20, md: 24, lg: 24 })),
    h5: __assign({ fontWeight: 700, lineHeight: 1.5, fontSize: pxToRem(18) }, responsiveFontSizes({ sm: 19, md: 20, lg: 20 })),
    h6: __assign({ fontWeight: 700, lineHeight: 28 / 18, fontSize: pxToRem(17) }, responsiveFontSizes({ sm: 18, md: 18, lg: 18 })),
    subtitle1: {
        fontWeight: 600,
        lineHeight: 1.5,
        fontSize: pxToRem(16),
    },
    subtitle2: {
        fontWeight: 600,
        lineHeight: 22 / 14,
        fontSize: pxToRem(14),
    },
    body1: {
        lineHeight: 1.5,
        fontSize: pxToRem(16),
    },
    body2: {
        lineHeight: 22 / 14,
        fontSize: pxToRem(14),
    },
    caption: {
        lineHeight: 1.5,
        fontSize: pxToRem(12),
    },
    overline: {
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(12),
        textTransform: 'uppercase',
    },
    button: {
        fontWeight: 700,
        lineHeight: 24 / 14,
        fontSize: pxToRem(14),
        textTransform: 'capitalize',
    },
};
export default typography;
